@import "designSystem/colors.scss";

.confirm-modal {
    max-height: unset !important;
    height: unset !important;
    width: 400px !important;
    background-color: white;
    padding: 15px;

    .confirm-modal-content {
        color: $font-primary;

        .title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .content {
            font-size: 12px;
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 30px;

            button:first-of-type {
                margin-right: 10px;
            }
        }
    }
}
