@import "designSystem/colors.scss";

.step-two-wrapper {
    padding: 30px;
    padding-right: 0;
    width: 100%;

    .organization-wrapper {
        display: grid;
        grid-template-columns: 1fr minmax(400px, 1fr);
        width: fit-content;
        padding-right: 30px;
        margin: 0 auto;

        .section-title {
            font-weight: 600;
            color: $font-primary;
            font-size: 12px;
            margin-bottom: 10px;
            letter-spacing: 0.5px;
        }

        .items-not-found {
            color: $font-primary;
            font-size: 14px;
        }

        .organization-list {
            background-color: $background;

            .item-cards {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 10px;
                padding: 10px;

                .item-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $font-light;
                    padding: 10px 5px 5px 5px;
                    width: 160px;
                    height: 95px;
                    position: relative;
                    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
                        repeat;

                    .icon {
                        font-size: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .text {
                        font-size: 10px;
                        margin: 5px 0 10px 0;
                    }

                    .input {
                        width: 100%;

                        select {
                            width: 100%;
                        }
                    }

                    .remove-item {
                        width: 25px;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 0;
                        top: 0;
                        opacity: 0.4;
                        transition: 0.1s opacity;

                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                            transition: 0.1s opacity;
                        }
                    }
                }
            }
        }

        .file-list {
            .file-items {
                padding: 10px;

                .file-item {
                    display: flex;
                    border: 1px solid $border-color;
                    margin-bottom: 5px;

                    .file-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        background-color: $primary-dark;
                        color: $background-light;
                        font-size: 22px;
                    }

                    .file-content {
                        display: flex;
                        flex-direction: column;
                        color: $font-primary;
                        padding: 5px 10px;
                        box-sizing: border-box;
                        background-color: $background-light;
                        flex: 1;

                        .file-title {
                            font-size: 12px;
                        }

                        .file-path {
                            font-size: 10px;
                        }
                    }

                    .file-remove {
                        width: 25px;
                        background-color: $background-light;
                        color: $font-primary;

                        .icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0.6;
                            transition: 0.1s opacity;
                            height: 100%;
                            width: 100%;

                            &:hover {
                                cursor: pointer;
                                opacity: 1;
                                transition: 0.1s opacity;
                            }
                        }
                    }
                }
            }
        }

        .section-buttons {
            display: grid;
            grid-template-columns: repeat(3, 160px);
            grid-gap: 10px;
            padding: 10px;
            grid-column: span 2;
        }

        .confirm {
            grid-column: span 2;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .add-item-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $font-light;
        padding: 5px;
        font-size: 35px;
        opacity: 0.4;
        transition: 0.1s opacity;
        width: 160px;
        height: 95px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
            repeat;

        &.disabled {
            flex-direction: column;

            &:hover {
                opacity: 0.4;
                cursor: unset;
            }

            .empty {
                font-size: 11px;
                text-align: center;
                margin-top: 5px;
            }
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
            transition: 0.1s opacity;
        }
    }

    .organization-wrapper-loading {
        padding-right: 30px;

        .items-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 160px);
            grid-gap: 10px;
            padding: 10px;
        }

        .button-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }
}
