@import "designSystem/colors.scss";

.kalibrierung-scan-wrapper {
    .kalibrierung-scan-content {
        font-size: 12px;
        color: $font-primary;
        letter-spacing: 1px;
        display: flex;
        flex-direction: column;
        padding-right: 5px;

        .kpmg-table-selectors {
            order: 0;
        }

        .table-skeleton-loading-component {
            order: 1;
        }

        .kpmg-table {
            order: 1;

            thead {
                background-image: url("../../../../assets/images/sub_header.png");
                background-size: 100% calc(100% + 2px);

                tr {
                    &.no-border {
                        th {
                            border: none !important;
                        }
                    }
                    th {
                        background: unset !important;
                    }
                }

                .header-tabs {
                    display: flex;
                    align-items: center;

                    .table-head {
                        padding-bottom: 3px;
                        margin-bottom: 3px;
                        font-size: 14px;
                        
                        &:first-child {
                            margin-right: 20px;
                        }

                        &.active {
                            // border-bottom: 1px solid;
                            font-weight: 600;
                        }

                        svg{
                            margin-right: 3px;
                        }
                    }
                }

                .row-header {
                    border-right: 1px solid $border-color-dark;

                    th {
                        border-left: unset;
                        border-right: unset;
                        padding: 10px;

                        &.tip {
                            vertical-align: bottom;

                            div {
                                font-size: 11px;
                                display: flex;
                                align-items: flex-end;
                            }
                        }

                        .section-header-item {
                            border-bottom: 1px solid $primary-dark;
                            padding-bottom: 3px;
                            margin-bottom: 3px;
                            width: fit-content;
                            font-size: 14px;
                            white-space: nowrap;

                            &.active,
                            &:hover {
                                cursor: pointer;
                                border-bottom: 1px solid;
                            }
                        }
                    }
                }
            }

            tbody {
                .table-action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $font-primary;
                    font-size: 18px;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }
                .csv-actions {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-bottom: 3px;
                    font-size: 12px;

                    .download-csv {
                        display: flex;
                        align-items: center;
                        margin-left: 20px;

                        &.disabled {
                            pointer-events: none;
                        }

                        .csv-loading {
                            margin: 0 auto;
                        }

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        span {
                            margin-left: 7px;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }

        .scan-config-component {
            order: 1;
        }

        .graph-axis {
            order: 2;
            margin-top: 30px;

            p {
                font-weight: 600;
            }

            .fields {
                display: flex;
                margin-bottom: 20px;

                .field {
                    margin-right: 20px;

                    input {
                        margin-top: 5px;
                        width: 100px;
                    }

                    .clear-db-info {
                        margin-top: 5px;
                        font-size: 12px;
                    }
                }
            }
        }

        .actions {
            margin-top: 20px;
            order: 3;
            display: grid;
            grid-template-columns: 1fr 160px 160px;
            grid-gap: 10px;
            width: fit-content;
            margin-left: auto;

            div {
                word-break: break-word;
                text-align: right;
                display: flex;
                align-items: center;
            }
        }
    }
}
