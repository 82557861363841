@import "designSystem/colors.scss";

.license-key-component {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: $font-primary;

    .license-key-content {
        display: flex;
        flex-direction: column;

        .field {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .title {
                margin-right: 5px;
                font-weight: 600;
            }

            .value {
            }
        }

        hr {
            width: 100%;
            height: 1px;
            background-color: rgb(214, 214, 214);
            border: 0;
            margin-bottom: 30px;
        }

        .new-license {
            display: flex;
            flex-direction: column;

            .new-license-title {
                font-weight: 600;
            }

            textarea {
                width: 100%;
                resize: none;
                margin-top: 10px;
                box-sizing: border-box;
                padding: 10px;
            }

            button {
                margin-left: auto;
                margin-top: 20px;
            }
        }
    }
}
