@import "designSystem/colors.scss";

.euc-check-success {
    width: 100%;
    height: 100%;
    padding: 50px;
    max-width: 650px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .success-card {
        display: flex;
        background-color: rgb(231, 241, 232);
        padding: 15px 20px 50px 20px;

        .success-check {
            border: 3px solid #1c5c0a;
            color: #1c5c0a;
            height: 45px;
            width: 45px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 23px;
            margin-right: 20px;
            flex-shrink: 0;
        }

        .success-text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            width: 100%;

            .title {
                color: #1c5c0a;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 5px;
                letter-spacing: 1px;
                word-break: break-word;
            }

            .message {
                width: 100%;
            }

            .alias {
                border-bottom: 1px solid #cccccc;
                padding-bottom: 5px;
                margin-bottom: 5px;
                margin-top: 5px;
                word-break: break-word;
                word-wrap: break-word;
                white-space: normal;
                width: 100%;
            }

            .status {
                font-weight: 600;
                color: #363636;
                font-size: 18px;
                letter-spacing: 2px;
                word-break: break-word;
                word-wrap: break-word;
                white-space: normal;
            }
        }
    }

    .bottom-message {
        font-size: 12px;
        padding: 15px;
        letter-spacing: 0.5px;
        line-height: 15px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 60px;
        word-break: break-word;
    }

    button {
        margin-left: auto;
        font-weight: 600;
        min-width: 200px;
        padding: 8px;
        border: none;
        box-shadow: 2px 2px 7px #777777;
        font-size: 12px;
        letter-spacing: 0.5px;
        border-radius: 2px;
        word-break: break-word;
    }
}

@media only screen and (max-width: 1070px) {
    .euc-check-success {
        padding: 0;

        .success-card {
            .success-check {
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                font-size: 15px;
            }

            .success-text {
                .title {
                    font-size: 12px;
                }

                .message {
                    font-size: 18px;
                }
            }
        }
    }
}
