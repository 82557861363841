@import "designSystem/colors.scss";

.meine-validierungen {
    background-color: white;
    align-items: center;
    height: 100%;

    .error {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 23px;

        button {
            margin-top: 20px;
            width: 90px;
            height: 40px;
        }
    }

    .meine-validierungen-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0;
            font-size: 13px;
        }

        p {
            margin: 10px 0;
            font-size: 13px;
        }

        .send-user-icon {
            width: 100%;
            height: 100%;
            image-rendering: -webkit-optimize-contrast;
        }
    }
}
