@import "designSystem/colors.scss";

.design-wrapper {
    .design-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .section-header {
            color: $font-light;
            padding: 5px 10px;
            font-size: 13px;
            letter-spacing: 1px;
            margin-bottom: 20px;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
                repeat;
        }

        .screen-configuration {
            min-width: 0;

            .section-content {
                border: 1px solid $border-color;
                background-color: $background;
                padding: 10px 20px;

                .field-wrapper {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;

                    .field-title {
                        color: $font-primary;
                        font-size: 12px;
                        margin-bottom: 5px;
                        letter-spacing: 1px;
                    }

                    .field-input {
                        display: flex;
                        align-items: center;
                        height: 30px;

                        .input-filename {
                            height: 100%;
                            width: 100%;
                            background-color: $background-light;
                            border: 1px solid $border-color;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .screen-preview {
            min-width: 0;

            .section-content {
                .screen-thumbnail {
                    border: 1px solid $border-color;
                    // height: 300px;
                    padding: 10px;
                    position: relative;

                    .screen-thumbnail-wrapper {
                        width: 1980px;
                        height: 1080px;
                        transform: scale(0.25);
                        transform-origin: top left;
                    }

                    .overlay {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .screen-preview-actions {
                    margin-top: 50px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 10px;

                    div {
                        word-break: keep-all;
                    }
                }

                .app-version {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 14px;
                    margin-top: 100px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1070px) {
    .design-wrapper {
        .design-content {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
    }
}
