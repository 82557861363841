@import "designSystem/colors.scss";

.modal-layout {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgb(134, 134, 134);
    color: $font-light;

    min-height: unset;
    height: auto;
    max-height: calc(100% - 40px);

    min-width: unset;
    width: 500px;
    max-width: calc(100% - 40px);

    &:focus {
        outline: 0;
    }

    .modal-header {
        min-height: 55px;
        display: flex;
        align-items: center;
        background-color: #c9d0dc;
        background-image: url("../../assets/images/sub_header.png");
        background-size: 100% 100%;

        .modal-header-content {
            flex: 1;
            padding: 0 15px;

            h3 {
                font-size: 14px;
                color: $font-light;
                font-weight: 500;
                margin: 0;
            }

            p {
                font-size: 12px;
                margin: 0;
                margin-top: 3px;
                color: $accent-dark;
            }
        }

        .modal-header-close {
            color: $font-light;
            height: 100%;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .modal-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: $font-primary;
        font-size: 12px;
        overflow: auto;
        background-color: $background-light;

        .modal-description {
            background-color: #f9f9f9;
            margin: 0;
            padding: 10px 15px;
        }

        .content {
            flex: 1;
            padding: 15px;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        height: 40px;
        background-color: $background-light;
        padding: 0 15px;
    }
}
