.info-tooltip {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .help-tooltip {
        pointer-events: auto;
        max-width: 500px;

        a {
            font-weight: 600;
            color: #ea9b38;
        }
    }

    .info-icon {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-text {
        font-size: 13px;
    }
}
