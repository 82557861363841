@import "designSystem/colors.scss";

.input-wrapper {
    &.error {
        .input-content {
            & > input,
            & > select {
                border: 1px solid $error !important;
            }
        }
    }

    .input-content {
        .react-datepicker-wrapper {
            width: 100%;
        }

        & > input,
        & > select {
            box-sizing: border-box;
            width: 100%;
        }
    }
}
