@import "designSystem/colors.scss";

.scatter-chart-component {
    width: 100%;
    height: 100%;

    .highcharts-tooltip {
        pointer-events: auto;
        width: 370px;
        height: 110px;
        background: transparent;

        path {
            fill: none;
        }
    }

    .highcharts-container {
        .highcharts-text-outline {
            fill: unset;
            stroke: unset;
        }
    }

    .chart-tooltip {
        background-color: rgba(82, 86, 98, 0.9);
        color: $font-light;
        padding: 5px 7px;
        width: 370px;
        height: 110px;
        pointer-events: auto;

        .tooltip-header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            border-bottom: 1px solid $font-light;
            padding-bottom: 5px;
            margin-bottom: 5px;

            .circle {
                width: 15px;
                height: 15px;
                flex-shrink: 0;
                background-color: $point-gray;
                border: 1px solid #525662;
                margin-right: 5px;
                border-radius: 100%;
            }

            .close-tooltip {
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                color: $accent-dark;
            }
        }

        .tooltip-content {
            font-size: 11px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            .field-content {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.column {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: 10px;
                    width: 100%;

                    select {
                        border: none;
                        height: 20px;
                        font-size: 11px;
                        width: 100%;
                        color: $accent-dark;
                        background-color: $primary-dark;
                    }
                }
            }
        }
    }

    .draggable-axis {
        &:hover {
            cursor: all-scroll;
        }
    }
}
