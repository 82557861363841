@import "designSystem/colors.scss";

.step-six-wrapper {
    padding: 30px;
    width: 100%;
    font-size: 12px;

    .section-title {
        color: $font-light;
        margin-bottom: 15px;
        padding: 3px 15px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
            repeat;
    }

    .sections-wrapper {
        display: flex;

        .section-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: $background;
            padding: 20px 30px;
            color: $font-primary;

            &.section-config-name {
                margin-right: 15px;
            }

            p {
                margin: 0;
                margin-bottom: 20px;
            }

            label {
                margin-bottom: 5px;
            }

            input {
                height: 30px;
            }

            .button-wrapper {
                margin-top: auto;
                margin-left: auto;

                button {
                    margin-top: 20px;
                    width: fit-content;
                }
            }
        }
    }
}
