@import "designSystem/colors.scss";

.button-component {
    padding: 5px 15px;
    color: $font-light;
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-family: "Verdana";
    font-weight: 500;
    background-color: $primary-dark;

    .button-component-loading {
        flex: 1;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17px;
    }

    .button-content {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: normal;

        & > svg {
            margin-right: 5px;
        }
    }

    &.cancel {
        background-color: $font-disabled;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: 0.9;
    }

    &.disabled {
        opacity: 0.7;

        &:hover {
            cursor: unset;
        }
    }

    &.round {
        border-radius: 32px;
    }

    &.light {
        background: rgb(236, 236, 236);
        background: linear-gradient(0deg, rgba(236, 236, 236, 1) 0%, rgba(255, 255, 255, 1) 100%);
        border: 1px solid #afafaf;
        color: $font-primary;
    }

    &.small {
        height: 30px;
        padding: 0px 10px;
        font-size: 12px;
    }

    &.very-small {
        height: 22px;
        padding: 0px 10px;
        font-size: 12px;
    }

    &.no-actions {
        pointer-events: none;
    }

    &.danger {
        background-color: $error;
    }
}
