@import "designSystem/colors.scss";

.step-one-wrapper {
    padding: 30px;
    width: 100%;
    margin: 0 auto;

    p {
        color: $font-primary;
        font-size: 12px;
        margin: 0;
        margin-bottom: 15px;
        letter-spacing: 1px;

        &.title {
            font-weight: 600;
        }
    }

    .select-field {
        display: flex;
        flex-direction: column;
        background-color: $background;
        padding: 20px;
        color: $font-primary;

        .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            margin-bottom: 10px;

            span {
                margin-right: 10px;
            }
        }

        .field {
            display: flex;
            align-items: center;

            select {
                flex: 1;
                margin-right: 20px;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                border: 1px solid $border-color;
            }
        }
    }

    .step-one-loading {
        width: 100%;
    }
}
