@import "designSystem/colors.scss";

.table-action-button {
    margin: 0 3px;
    width: 20px;
    height: 20px;
    border: 1px solid $primary-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-light;
    color: $primary-dark;
    font-size: 22px;
    box-sizing: border-box;

    &.disabled {
        opacity: 0.6;

        &:hover {
            cursor: not-allowed;
            background: unset;
        }
    }

    svg {
        path,
        polyline {
            stroke: $primary-dark;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $background;
    }
}
