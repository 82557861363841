@import "designSystem/colors.scss";

.file-tree-modal {
    max-height: unset !important;
    width: 650px !important;
    height: 600px !important;

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px 50px 0 50px;

        .tree-wrapper {
            border: 1px solid rgb(236, 236, 236);
            // padding: 10px;
            box-sizing: border-box;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            .tree-path {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;
                height: 50px;
                overflow-x: auto;
                overflow-y: hidden;
                background-color: #f3f3f3;
                flex-shrink: 0;
                // box-shadow: 0 5px 5px -5px #333;
                // border-bottom: 1px solid black;

                &::-webkit-scrollbar {
                    height: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgb(204, 204, 204);
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(168, 168, 168);
                }

                .tree-path-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 30px;

                    .tree-path-item__name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        color: #585858;
                        font-size: 16px;
                        font-family: system-ui;
                        padding: 0 5px;
                        transition: background-color 0.2s;
                        border-radius: 8px;
                        font-weight: 500;
                        white-space: nowrap;

                        &:hover {
                            background-color: #dadada;
                            transition: background-color 0.2s;
                            cursor: pointer;
                        }
                    }

                    .tree-path-item__icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        margin: 0 5px;
                        padding-top: 3px;
                    }
                }
            }

            .tree-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 50px;
                border-top: 1px solid #e0e0e0;
                user-select: none;

                &:last-child {
                    border-bottom: 1px solid #e0e0e0;
                }

                &.selected {
                    background-color: #e8f0fe;

                    .tree-row__name {
                        color: #1967d2;
                    }
                }

                &:hover:not(.selected) {
                    cursor: pointer;
                    background-color: #f1f1f1;
                }

                .tree-row__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    font-size: 24px;
                    flex-shrink: 0;
                }

                .tree-row__name {
                    font-size: 15px;
                    color: #585858;
                }

                .tree-row__icon-skeleton {
                    width: 20px;
                    height: 20px;
                    margin: 0 15px;
                }

                .tree-row__name-skeleton {
                    width: 100%;
                    height: 20px;
                }
            }

            .folder-message {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;

                &.forbidden {
                    .icon {
                        color: #721c24;
                        background-color: #f8d7da;
                    }

                    .message {
                        color: #721c24;
                    }
                }

                &.empty {
                    .icon {
                        color: #383d41;
                        background-color: #e2e3e5;
                    }

                    .message {
                        color: #383d41;
                    }
                }

                .icon {
                    width: 80px;
                    height: 80px;
                    font-size: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                }

                .message {
                    margin-top: 20px;
                    font-family: system-ui;
                    font-weight: 500;
                }
            }
        }

        .action-buttons {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;
            padding-bottom: 20px;
            flex-basis: auto;

            button:last-of-type {
                margin-left: 20px;
            }
        }
    }
}
