@import "designSystem/colors.scss";

.select-user-modal {
    color: $font-light;
    min-height: unset !important;
    height: auto !important;
    width: 500px !important;
    font-size: 14px;

    .select-user-modal-wrapper {
        display: flex;
        flex-direction: column;
        color: $font-primary;
        font-size: 12px;

        .modal-actions {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            background-color: $background-light;
            height: 90px;

            .inputs-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .input-row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;

                    label,
                    input {
                        margin-left: 5px;
                    }

                    .input-text {
                        height: 25px;
                        width: 250px;
                    }

                    button {
                        margin-left: 5px;
                        height: 25px;
                        width: 25px;
                    }
                }
            }
        }
    }
}
