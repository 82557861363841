@import "designSystem/colors.scss";

.start-scan-wrapper {
    height: 100%;
    width: 100%;

    .start-scan-content {
        height: 100%;
        width: 100%;
    }
}
