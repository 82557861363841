@import "designSystem/colors.scss";

.validation-download-modal {
    max-height: unset !important;
    width: 850px !important;
    height: 550px !important;

    .modal-header {
        .validation-download-info {
            display: flex;
            flex-direction: column;

            hr {
                width: 100%;
            }

            .file-overview-details {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                font-size: 12px;
                color: $font-light;

                .file-overview-details__column {
                    .file-detail:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .file-icon {
            color: $font-light;
            position: absolute;
            top: 12px;
            left: 25px;

            .status-disabled {
                width: 15px;
                height: 15px;
                border-radius: 5px;
                background-color: #eaeaea;
                border: 1px solid $primary-dark;
            }
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        color: $primary-dark;

        .details-tab {
            font-size: 12px;
            padding: 30px 80px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;

            .flex {
                align-items: stretch;
                div:first-child {
                    margin-right: 10px;
                }
            }

            .submit-info {
                font-weight: 600;
                margin-bottom: 20px;
            }

            .question {
                margin-bottom: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .question-title {
                    font-weight: 700;
                    margin-bottom: 5px;
                }

                .question-answer {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 5px 7px;
                    border-radius: 3px;
                    background-color: #fafafa;
                    color: $font-dark;
                    border: 1px solid $border-color;

                    &.big {
                        min-height: 60px;
                        align-items: flex-start;
                    }
                }

                textarea {
                    background-color: #fff !important;
                    vertical-align: top;
                    margin: 0;
                    box-sizing: border-box;
                    width: unset;
                    max-width: 100%;
                }

                .filename {
                    background-color: #f7f7f7;
                }
            }

            hr {
                width: 100%;
                color: #e9e9e9;
                margin-bottom: 20px;
            }
        }

        .files-attached {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 10px;

            .file {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                font-size: 12px;
            }

            img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
                object-fit: contain;
            }
        }

        .empty-tab {
            font-size: 12px;
        }

        .pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
            font-size: 12px;

            div {
                margin: 0 3px;
                height: 25px;
                width: 25px;
                border: 1px solid rgb(124, 124, 124);
                background-color: $background;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.disabled {
                    opacity: 0.6 !important;
                    cursor: not-allowed !important;
                    pointer-events: none;
                }

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
        .actions {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 20px;

            .confirm-button {
                padding: 5px 15px;

                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        .field {
            select {
                flex: 1;
                width: 100%;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                border: 1px solid $border-color;
            }
        }
    }
}
