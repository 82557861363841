@import "designSystem/colors.scss";

.email-settings-component {
    width: 100%;
    height: 100%;

    .email-settings-content {
        width: 100%;
        height: 100%;
        padding: 20px;

        .form-wrapper {
            width: 100%;

            .center-form {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .limit-width {
                width: 100%;
                max-width: 500px;
            }

            .email-input {
                margin-bottom: 30px;
                display: flex;

                .input-title {
                    font-size: 13px;
                    word-break: break-word;
                }

                .input-content {
                    flex: 1;
                    min-width: 0;

                    .input-body {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: column;

                        .flex {
                            display: flex;
                            align-items: center;

                            select,
                            input {
                                width: 100%;
                                padding: 3px 7px;
                                border-radius: 3px;
                                height: 28px;
                                background-color: #f7f7f7;

                                &:disabled {
                                    background-color: #181717;
                                    border-color: #929292;
                                    cursor: not-allowed;
                                }
                                &::placeholder{
                                    color: #929292;
                                }
                            }
                        }

                        .filename {
                            background-color: #f7f7f7;
                        }

                        .error-message {
                            font-size: 12px;
                            font-weight: 600;
                            color: #e43a3a;
                            margin-top: 5px;
                        }
                    }
                }
            }

            .email-content-input {
                textarea {
                    width: 90%;
                    padding: 3px 7px;
                    border-radius: 3px;
                    height: 28px;
                    background-color: #fff;
                    height: 80px;

                    &:disabled {
                        background-color: #dadada;
                        border-color: #929292;
                        cursor: not-allowed;
                    }
                }
            }

            .actions {
                margin-top: 50px;
                display: grid;
                grid-template-columns: 1fr 160px 160px;
                grid-gap: 10px;
                width: fit-content;
                margin-left: auto;

                div {
                    word-break: break-word;
                    text-align: right;
                }
            }
        }
    }
}

.receivers-modal {
    max-height: unset !important;
    height: unset !important;
    width: unset !important;
    min-width: 300px;
    background-color: white;
    padding: 20px;

    .receivers-modal-content {
        color: $font-primary;

        .title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .content {
            font-size: 12px;
        }

        .receivers-input {
            display: flex;

            .input-row {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                label,
                input {
                    margin-left: 5px;
                }

                .input-text {
                    height: 25px;
                    width: 250px;
                }

                button {
                    margin-left: 5px;
                    height: 25px;
                    width: 25px;
                }
            }

            .email-input {
                font-size: 13px;
                width: 100%;

                .input-title {
                    margin-bottom: 5px;
                }

                input {
                    width: 100%;
                    padding: 3px 7px;
                    border-radius: 3px;
                    height: 28px;
                    background-color: #f7f7f7;

                }
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 30px;

        }
    }
}
