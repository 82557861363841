@import "designSystem/colors.scss";

.wizard-wrapper {
    height: 100%;
    width: 100%;
    box-shadow: 0 0 2px black;
    display: flex;
    flex-direction: column;

    .wizard-steps {
        display: flex;
        background-image: url("../../../../../../assets/images/sub_header.png");
        background-size: contain;
        background-repeat: round;
        color: white;
        height: 90px;
        position: relative;

        .wizard-text {
            position: absolute;
            left: 15px;
            top: 15px;
            width: 110px;

            .title {
                font-weight: 600;
                margin-bottom: 5px;
            }

            .subtitle {
                font-size: 13px;
                letter-spacing: 1px;
            }
        }

        .wizard-step {
            flex: 1;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.hoverable {
                cursor: pointer;
            }

            img {
                object-fit: contain;
            }

            .step-name {
                margin: 5px 0;
                font-weight: 600;
            }

            .step-description {
                letter-spacing: 0.5px;
                padding-bottom: 5px;
                border-bottom: 2px solid;
            }
        }
    }

    .wizard-content {
        flex: 1;
        overflow-x: auto;
    }
}
