.arrow-button {
    margin-top: 10px;
    padding: 15px 20px;
    background: rgb(247, 178, 44);
    // background: linear-gradient(0deg, rgb(245, 173, 29) 0%, rgb(253, 221, 116) 100%);
    background: linear-gradient(to bottom, #fedc55, #f8b700);
    max-width: 330px;
    min-width:200px;
    position: relative;
    padding-right: 50px;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
    }

    .arrow-button__title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 5px;
        letter-spacing: 1px;
        //border-bottom: 1px solid rgba(247, 178, 44, 1);
        border-bottom: 1px solid rgb(221, 170, 70);
        padding-bottom: 5px;
        word-break: break-word;
    }

    .arrow-button__text {
        margin-top: 10px;
        font-size: 12.5px;
        line-height: 15px;
        word-break: break-word;
    }

    .arrow {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        border-top: 56px solid rgb(239, 240, 241);
        border-bottom: 56px solid rgb(239, 240, 241);
        border-left: 30px solid transparent;
        width: 0;
    }
}
