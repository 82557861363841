.wizard-modal {
    max-height: unset !important;
    height: 80% !important;
    width: 1200px !important;

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px;
    }
}
