@import "designSystem/colors.scss";

.euc-check2-wrapper {
    .euc-check2-content {
        font-size: 12px;
        color: $font-primary;
        letter-spacing: 1px;

        table {
            thead {
                background-image: url("../../../../assets/images/sub_header.png");
                background-size: 100% calc(100% + 2px);

                tr {
                    th {
                        background: unset !important;
                    }

                    &.section-header {
                        th {
                            color: $font-light;
                            font-size: 14px;
                            padding: 10px 5px 5px 5px;
                            border: 1px solid $border-color-dark;
                            border-bottom: none;
                        }
                    }
                }
            }

            td {
                .sort-arrows {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .sort-arrow {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0.5;
                        transition: opacity 0.15s;

                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                            transition: opacity 0.15s;
                        }
                    }
                }

                .allow-alias {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: fit-content;

                    input:hover,
                    label:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .actions {
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 160px 160px;
            grid-gap: 10px;
            width: fit-content;
            margin-left: auto;

            div {
                word-break: break-word;
                text-align: right;
            }
        }
    }
}