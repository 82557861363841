@import "designSystem/colors.scss";

.chart-point-list-modal {
    color: $font-light;
    min-height: unset !important;
    height: auto !important;
    width: 500px !important;
    max-height: 300px;
    font-size: 14px;

    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: $primary-dark;
        padding: 15px;

        .close-tooltip {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .content-body {
        display: flex;
        flex-direction: column;
        color: $font-primary;
        font-size: 12px;
        background-color: white;
        padding: 20px;
        overflow-y: auto;

        .info-details {
            margin-bottom: 15px;

            p {
                margin: 0;
            }
        }

        table {
            tbody {
                tr {
                    &:hover {
                        cursor: pointer;
                    }

                    .center {
                        text-align: center;
                    }
                }
            }
        }
    }
}
