@import "designSystem/colors.scss";

.file-overview-modal {
    max-height: unset !important;
    width: 85% !important;
    height: 85% !important;

    .modal-header {
        .file-overview-info {
            display: flex;
            flex-direction: column;

            hr {
                width: 100%;
            }

            .file-overview-details {
                font-size: 12px;
                color: $font-light;

                .file-detail:not(:last-child) {
                    margin-bottom: 5px;
                }

                table {
                    width: 100%;
                    table-layout: fixed;
                }

                .file-detail {
                    .file-detail-alias {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        input {
                            margin-left: 5px;
                            width: 150px;
                            background-color: #455065;
                            border: 1px solid #616671;
                            color: #eaeaea;
                            padding: 0px 5px;
                        }

                        .action-button {
                            width: 20px;
                            font-size: 17px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 5px;

                            svg {
                                path,
                                polyline {
                                    stroke: $font-light;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }

        .file-icon {
            color: $font-light;
            position: absolute;
            top: 12px;
            left: 25px;

            .status-disabled {
                width: 15px;
                height: 15px;
                border-radius: 5px;
                background-color: #eaeaea;
                border: 1px solid $primary-dark;
            }
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px 50px;
        color: $primary-dark;

        .tabs-wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;

            .navbar-tabs-wrapper {
                min-width: 100%;
                flex-basis: auto;
                flex-grow: 1;

                .tab-wrapper {
                    flex-basis: auto;
                }
            }
        }

        .scan-ergebnis-tab {
            font-size: 12px;
            position: relative;

            .values-overview {
                display: flex;
                margin-bottom: 20px;

                .main-value-wrapper {
                    flex: 1 1 50%;

                    &:first-of-type {
                        padding-right: 20px;
                    }

                    .main-value {
                        display: flex;
                        justify-content: space-between;
                        background-color: #f3f3f3;
                        border: 1px solid $border-color;
                        padding: 3px 10px;
                        font-weight: 600;
                    }
                }
            }

            .tables-title {
                border-bottom: 1px solid $border-color;
                font-weight: 600;
                margin-bottom: 15px;
            }

            .tables {
                display: flex;

                div {
                    flex: 1 1 50%;

                    &.complexity-table {
                        padding-right: 20px;
                    }

                    table {
                        border-collapse: collapse;
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    padding: 3px 0;
                                    font-weight: 500;

                                    &:first-of-type {
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                background-color: $background-light;

                                &:nth-child(odd) {
                                    background-color: #f3f3f3;
                                }

                                td {
                                    border: 1px solid $border-color;
                                    padding: 3px 10px;
                                    text-align: center;

                                    &:first-of-type {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tables-info {
                margin-top: 20px;
                font-size: 11px;
                color: $font-disabled;
            }

            .tables-error{
                margin-top: 20px;
                font-size: 14px;
                color: $error;
                display: flex;
                align-items: center;

                svg{
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
        }

        .questions-tab {
            font-size: 12px;
            padding: 0 80px 20px 80px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            position: relative;

            .questions-wrapper {
                margin-bottom: 20px;
            }

            .section-title {
                font-size: 13px;
                font-weight: 600;
                text-decoration: underline;
                margin-bottom: 20px;
            }

            .question {
                margin-bottom: 20px;

                .section-title-content {
                    display: flex;
                    align-items: center;
                    color: $font-primary;
                    margin-top: 25px;

                    .section-title-icon {
                        font-size: 13px;
                        margin-right: 6px;
                    }

                    .section-title-text {
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 13px;
                    }
                }

                .question-title {
                    font-weight: 700;
                    margin-bottom: 5px;
                }

                .question-answer {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 3px 7px;
                    border-radius: 3px;
                    min-height: 28px;
                    background-color: #fafafa;
                    color: $font-dark;
                    border: 1px solid $border-color;
                    white-space: pre-line;

                    &.different {
                        border-color: #fed12e;
                        border-width: 2px;
                    }
                }

                .file-row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    text-decoration: none;
                    color: $primary-dark;
                    width: fit-content;

                    &:hover {
                        color: $primary-dark;
                        text-decoration: underline;
                    }

                    span {
                        margin-left: 5px;
                        font-size: 13px;
                    }
                }
            }
        }

        .duplicates-tab {
            .download-csv {
                margin-bottom: 20px;
                text-align: right;
            }
        }

        .tab-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            font-size: 11px;

            .left-items {
                display: flex;
                align-items: center;
                margin-right: 30px;
            }

            .right-items {
                display: flex;
                align-items: center;
            }
        }

        .empty-tab {
            font-size: 12px;
        }

        .pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 12px;
            margin-left: 10px;

            div {
                margin: 0 3px;
                height: 20px;
                width: 20px;
                border: 1px solid rgb(124, 124, 124);
                background-color: $background;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.disabled {
                    opacity: 0.6 !important;
                    cursor: not-allowed !important;
                    pointer-events: none;
                }

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
    }
}
