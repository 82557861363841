@import "designSystem/colors.scss";

.step-three-wrapper {
    padding: 30px;
    width: fit-content;
    margin: 0 auto;

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 220px);
        justify-content: center;
        grid-gap: 15px;

        .card-options {
            max-width: 220px;
            font-size: 12px;
            letter-spacing: 1px;
            display: flex;
            flex-direction: column;

            & > .title {
                color: $font-light;
                margin-bottom: 15px;
                padding: 3px 5px;
                background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
                    repeat;
            }

            .content {
                flex: 1;
                background-color: $background;
                color: $font-primary;
                padding: 10px 15px;

                .section {
                    margin-bottom: 20px;

                    .title {
                        font-weight: 600;
                        padding-bottom: 3px;
                        margin-bottom: 5px;
                    }

                    .title-border {
                        font-weight: 600;
                        border-bottom: 1px solid $border-color;
                        padding-bottom: 3px;
                        margin-bottom: 5px;
                    }

                    ul {
                        padding-left: 20px;

                        li{
                            margin-bottom: 10px;
                        }
                    }

                    .input-row {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;

                        .input-wrapper {
                            flex: 1;
                            min-width: 0;
                        }

                        input {
                            flex: 1;
                            min-width: 0;
                            width: 100%;
                        }

                        span {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
    }

    .checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        &:hover {
            cursor: pointer;
        }

        .checkbox-square {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15px;
            width: 15px;
            background-color: $background-light;
            border: 1px solid $border-color;
            margin-right: 7px;
        }

        .checkbox-label {
            color: $font-primary;
            user-select: none;
        }
    }

    .step-three-loading {
        display: grid;
        grid-template-columns: repeat(3, 220px);
        justify-content: center;
        grid-gap: 15px;
    }
}
