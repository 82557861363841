@import "designSystem/colors.scss";

.questionnaire-component {
    width: 100%;

    .question {
        margin-bottom: 30px;
        display: flex;

        .question-title {
            font-size: 13px;
            word-break: break-word;
        }

        .question-content {
            flex: 1;
            min-width: 0;

            .question-body {
                margin-top: 8px;
                display: flex;
                flex-direction: column;

                .flex {
                    display: flex;
                    align-items: center;

                    select,
                    input {
                        width: 100%;
                        padding: 3px 7px;
                        border-radius: 3px;
                        height: 28px;
                        background-color: #f7f7f7;
                    }

                    button {
                        margin-left: 10px;
                        flex-shrink: 0;
                        
                        div {
                            white-space: nowrap;
                        }
                    }
                }

                .filename {
                    background-color: #f7f7f7;
                }

                .error-message {
                    font-size: 12px;
                    font-weight: 600;
                    color: #e43a3a;
                    margin-top: 5px;
                }
            }
        }
    }
}
