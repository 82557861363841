@import "designSystem/colors.scss";

.add-blacklist-modal {
    color: $font-light;
    min-height: unset !important;
    height: auto !important;
    width: 700px !important;
    font-size: 14px;

    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        background-color: $primary-dark;
        padding: 15px;
        background-image: url("../../../../assets/images/sub_header.png");
        background-size: 100% calc(100% + 2px);

        .content-header__title {
            display: flex;
            flex-direction: column;

            .filename {
                margin-top: 5px;
                font-size: 12px;
            }
        }

        .close-tooltip {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .content-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: $font-primary;
        font-size: 12px;
        overflow: auto;
        background-color: $background-light;
        padding: 20px 50px;

        .input-flex-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;

            label {
                font-size: 12px;
                font-weight: 600;
                color: $font-primary;
                margin-bottom: 5px;
            }

            input, select {
                height: 30px;
                border: 1px solid $border-color;
                box-sizing: border-box;
            }
        }

        .action-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            button:last-of-type {
                margin-left: 20px;
            }
        }
    }
}
