@import "designSystem/colors.scss";

.listenansicht-wrapper {
    height: 100%;
    background-color: white;

    table {
        thead {
            background-image: url("../../../../../../assets/images/sub_header.png");
            background-size: 100% calc(100% + 2px);

            th {
                background: unset !important;
                white-space: nowrap;
            }

            .row-header {
                border-right: 1px solid $border-color-dark;

                th {
                    border-left: unset;
                    border-right: unset;
                    padding: 10px;

                    &.tip {
                        vertical-align: bottom;

                        div {
                            font-size: 11px;
                            display: flex;
                            align-items: flex-end;
                        }
                    }

                    .section-header-item {
                        border-bottom: 1px solid $primary-dark;
                        padding-bottom: 3px;
                        margin-bottom: 3px;
                        width: fit-content;
                        font-size: 14px;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;

                        .chart-type {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 8px;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .list-data-count {
                            margin-left: 20px;

                            .title {
                                margin-right: 5px;
                            }
                        }

                        & > span {
                            border-bottom: 1px solid;
                        }
                    }

                    .download-csv {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        font-size: 12px;
                        padding-bottom: 3px;

                        &.disabled {
                            pointer-events: none;
                        }

                        .csv-loading {
                            margin: 0 auto;
                        }

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        span {
                            margin-left: 7px;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                &.row-filters {
                    td {
                        background-color: $border-color;
                        padding: 3px 5px;

                        select,
                        input {
                            width: 100%;
                        }

                        .filter-select {
                            width: -webkit-fill-available;
                            text-align-last: center;
                        }
                    }
                }

                td {
                    .status-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .status-disabled {
                            width: 15px;
                            height: 15px;
                            border-radius: 5px;
                            background-color: #eaeaea;
                            border: 1px solid #aaaaaa;
                        }
                    }
                    .issues-row {
                        display: flex;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 3px;
                        }

                        &.success {
                            color: green;
                        }

                        &.error {
                            color: red;
                        }
                    }
                    .details-button {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: $font-light;
                        background-color: $primary-dark;
                        padding: 2px 7px;
                        &:hover {
                            cursor: pointer;
                            opacity: 0.9;
                        }
                        span {
                            margin-left: 5px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    .table-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 20px;
        color: $font-primary;

        .pagination {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            div {
                margin: 0 3px;
                height: 25px;
                width: 25px;
                border: 1px solid rgb(124, 124, 124);
                background-color: $background;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.disabled {
                    opacity: 0.6 !important;
                    cursor: not-allowed !important;
                    pointer-events: none;
                }

                &.active {
                    background-color: #bdbdbd;
                    border: 1px solid #636363;
                    color: #636363;
                }

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        .actions {
            select {
                margin-left: 5px;
                height: 27px;
                width: 50px;
            }
        }
    }
}
