@import "designSystem/colors.scss";

.user-list-modal {
    color: $font-light;
    min-height: unset !important;
    height: auto !important;
    max-height: 600px !important;
    width: 750px !important;
    font-size: 14px;

    .user-list-modal-wrapper {
        display: flex;
        flex-direction: column;
        color: $font-primary;
        font-size: 12px;
        overflow: auto;

        .table-wrapper {
            background-color: $background-light;

            table {
                thead {
                    tr {
                        th {
                            white-space: nowrap;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            text-align: left;
                        }

                        &:hover,
                        &.selected {
                            cursor: pointer;
                            background-color: #e0feff;
                        }
                    }
                }
            }

            .action-button {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}
