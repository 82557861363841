.euc-regularien {
    background-color: white;
    //display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    height: 100%;
    //width: 100%;
    
    .error {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 23px;

        button {
            margin-top: 20px;
            width: 90px;
            height: 40px;
        }
    }

    .euc-regularien-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0;
            font-size: 13px;
        }

        p {
            margin: 10px 0;
            font-size: 13px;
        }

        a {
            font-size: 13px;
        }
    }
}
