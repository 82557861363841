@import "designSystem/colors.scss";

.step-five-wrapper {
    padding: 30px;
    width: 100%;
    font-size: 12px;

    table {
        th {
            white-space: nowrap;
        }

        tbody {
            tr {
                td {
                    input {
                        text-align: center;
                    }
                }
            }
        }

        .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .action-buttons {
        display: flex;
        margin-top: 20px;

        .section-tip {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: $font-primary;
            margin-right: 20px;

            .title {
                font-weight: 600;
            }
        }

        .confirm {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}
