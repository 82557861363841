@import "designSystem/colors.scss";

.org-einheiten-wrapper {
    .org-einheiten-content {
        font-size: 12px;
        color: $font-primary;
        letter-spacing: 1px;

        .section-header {
            background-color: $primary-dark;
            color: $font-light;
            font-size: 14px;
            padding: 10px 5px 5px 5px;
            border: 1px solid $border-color-dark;
            border-bottom: none;
        }

        .section-content {
            margin-top: 30px;
            border: 1px solid $border-color;
            padding: 10px 20px 20px 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            .group {
                display: flex;
                flex-direction: column;

                .group-title {
                    color: $font-light;
                    padding: 5px 10px;
                    font-size: 13px;
                    letter-spacing: 1px;
                    margin-bottom: 20px;
                    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
                        repeat;
                }

                .group-content {
                    background-color: $background;
                    padding: 20px 10px;
                    flex: 1;
                    max-height: 300px;
                    overflow: auto;

                    .group-item {
                        display: flex;
                        align-items: center;
                        height: 25px;
                        margin-bottom: 5px;

                        &.active,
                        &:hover {
                            cursor: pointer;

                            .icon,
                            .name,
                            .remove-item {
                                background-color: $primary-medium;
                                color: $font-light;
                            }

                            .remove-item {
                                display: flex;
                            }
                        }

                        .icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            font-size: 5px;
                            padding: 5px;
                            padding-right: 10px;
                        }

                        .name {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 100%;
                            flex: 1;
                        }

                        .remove-item {
                            display: none;
                            justify-content: center;
                            align-items: center;
                            width: 25px;
                            height: 25px;
                            margin-left: 3px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        .actions {
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 160px 160px;
            grid-gap: 10px;
            width: fit-content;
            margin-left: auto;

            div {
                word-break: break-word;
                text-align: right;
            }
        }
    }
}
