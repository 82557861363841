@import "designSystem/colors.scss";

.berechtigungen-wrapper {
    .berechtigungen-content {
        font-size: 12px;
        color: $font-primary;
        letter-spacing: 1px;

        table {
            thead {
                background-image: url("../../../../assets/images/sub_header.png");
                background-size: 100% calc(100% + 2px);

                tr {
                    th {
                        user-select: none;
                        background: unset !important;

                        span {
                            white-space: nowrap;
                            line-height: 18px;
                        }

                        .table-head {
                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &:not(:first-of-type) {
                            min-width: 170px;
                            width: 170px;
                            font-size: 11px;
                        }

                        &:first-of-type {
                            // min-width: 280px;
                            width: 280px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        .permission {
                            padding: 5px 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $font-light;
                            font-size: 12px;
                            font-family: "Verdana";
                            font-weight: 500;
                            background-color: $primary-dark;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .allow-modal-permissions {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: fit-content;
            margin-top: 30px;

            label {
                color: #525662;
                font-weight: normal;
                font-size: 12px;
            }

            input:hover,
            label:hover {
                cursor: pointer;
            }
        }
    }
}
