@import "designSystem/colors.scss";

.download-csv-modal {
    width: 500px;

    .section {
        &:not(:last-of-type) {
            margin-bottom: 30px;
        }

        .section-title {
            font-weight: 600;
        }

        .section-description {
            font-size: 11px;
            margin-top: 20px;
            color: $border-color-dark;
        }

        .input-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 10px 0 10px 0;

            input {
                margin: 0;
                margin-right: 10px;
            }
        }
    }
}
