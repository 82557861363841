@import "designSystem/colors.scss";
.ubersicht-wrapper {
    .ubersicht-content {
        .table-wrapper {
            .stop-button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 2px 7px;
                cursor: pointer;

                svg{
                    margin-right: 5px;
                }
            }

            .details-button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $font-light;
                background-color: $primary-dark;
                padding: 2px 7px;
                &:hover {
                    cursor: pointer;
                    opacity: 0.9;
                }
                span {
                    margin-left: 5px;
                    white-space: nowrap;
                }
            }
        }
    }
}
