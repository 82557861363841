@import "designSystem/colors.scss";

.language-component {
    width: 100%;
    height: 100%;

    .language-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .error-message {
            color: #721c24;
            background-color: #f8d7da;
            border: 1px solid #f5c6cb;
            margin-bottom: 20px;
            padding: 10px;
        }

        .json-wrapper {
            max-height: 600px;
            flex: 1;

            .ace_editor {
                border: 1px solid rgb(231, 231, 231);
            }
        }

        .actions {
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 160px 160px;
            grid-gap: 10px;
            width: fit-content;
            margin-left: auto;

            div {
                word-break: break-word;
                text-align: right;
                display: flex;
                align-items: center;
            }
        }
    }
}