@import "designSystem/colors.scss";

.inventory-questions-wrapper {
    .inventory-questions-content {
        letter-spacing: 1px;
        font-size: 14px;
        color: $font-primary;

        hr {
            border: 0;
            height: 1px;
            width: 100%;
            background-color: #cccccc;
            margin: 20px 0;
        }

        .title {
            font-weight: 600;
            margin-bottom: 20px;
        }

        .question-wrapper {
            &.level-2 {
                margin-left: 20px;
            }

            &.level-3 {
                margin-left: 20px;
            }

            .question {
                margin-bottom: 20px;

                .question-type {
                    margin-bottom: 8px;
                    font-size: 12px;
                }
            }
        }

        .question-options {
            display: flex;
            align-items: center;

            .sort-arrows {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .sort-arrow {
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0.5;
                    transition: opacity 0.15s;

                    &.down {
                        margin-right: 20px;
                    }

                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.15s;
                    }
                }
            }

            .question-option {
                display: flex;
                align-items: center;
                font-size: 12px;
                margin-top: 5px;
                width: fit-content;

                &:first-of-type {
                    margin-right: 10px;
                }

                span {
                    margin-left: 5px;
                }
            }
        }

        .settings-option {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: fit-content;
            font-size: 12px;
            margin-top: 20px;
        }

        .actions {
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 160px;
            grid-gap: 10px;
            width: fit-content;
            margin-left: auto;

            div {
                word-break: break-word;
                text-align: right;
            }
        }
    }
}
