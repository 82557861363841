@import "designSystem/colors.scss";

.add-item-component {
    button {
        margin-right: 5px;
        width: 20px;
        height: 20px;
        padding: 0;

        .button-content {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    span {
        font-size: 12px;
        color: $font-primary;
    }
}
