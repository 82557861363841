@import "designSystem/colors.scss";

.chart-point-modal {
    background-color: rgba(82, 86, 98, 0.9);
    color: $font-light;
    padding: 5px 7px;
    min-height: unset !important;
    height: fit-content !important;
    max-height: unset !important;
    min-width: 400px !important;
    width: auto !important;

    .tooltip-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        border-bottom: 1px solid $font-light;
        padding-bottom: 5px;
        margin-bottom: 5px;

        .circle {
            width: 15px;
            height: 15px;
            flex-shrink: 0;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .filename {
            margin-right: 10px;
            max-width: 500px;
        }

        .close-tooltip {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            color: $accent-dark;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .tooltip-content {
        font-size: 11px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;

        & > div {
            display: flex;
            flex-direction: column;

            .field-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;

                .field-title {
                    margin-right: 5px;
                }

                &.column {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: auto;
                    padding-top: 15px;
                    width: 100%;

                    select {
                        margin-top: 5px;
                        background: #3b3e44;
                        font-size: 11px;
                        width: 100%;
                        border: 0;
                        height: 20px;
                        font-family: "Verdana";
                    }

                    .selector {
                        padding: 4px;
                        font-size: 11px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        background: rgba(0, 0, 0, 0.4);
                        margin-top: 5px;

                        &:hover {
                            cursor: pointer;
                            background: rgba(0, 0, 0, 0.3);
                        }
                    }
                }
            }
        }
    }
}
