@import "designSystem/colors.scss";

.info-text-modal {
    max-height: unset !important;
    height: unset !important;
    width: 650px !important;

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px 50px;

        .input-wrapper {
            display: flex;
            flex-direction: column;

            label {
                font-size: 12px;
                font-weight: 600;
                color: $font-primary;
                margin-bottom: 5px;
            }

            input {
                height: 30px;
                border: 1px solid $border-color;
                box-sizing: border-box;
            }
        }

        .action-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            button:last-of-type {
                margin-left: 20px;
            }
        }
    }
}
