@import "designSystem/colors.scss";

.color-picker-component {
    display: flex;
    height: 100%;
    width: 100%;

    input,
    select {
        height: 100%;
        box-sizing: border-box;
        border: 1px solid $border-color;
        color: $font-primary;

        &:focus {
            outline: 0;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .color-picker-type {
        flex: 2;
        min-width: 0;
        margin-right: 15px;

        select {
            width: 100%;
        }
    }

    .color-picker-value {
        flex: 3;
        display: flex;
        min-width: 0;

        .color-picker-rgb {
            input {
                flex: 1;
                min-width: 0
            }
        }

        .color-picker-hex {
            input {
                flex: 1;
                min-width: 0
            }
        }

        .color-picker-rgb,
        .color-picker-hex {
            display: flex;
            align-items: center;
            flex: 1;
            min-width: 0;

            span {
                margin: 0 3px 0 5px;
                font-size: 12px;
                color: $font-primary;
            }
        }

        .color-picker-popup {
            position: relative;
        }
    }
}
