@import "designSystem/colors.scss";

.euc-keywords-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .search-tab {
            display: flex;
            padding: 10px 40px;
            box-sizing: border-box;
            background-color: $background;
            height: 50px;

            input {
                flex: 1;
                margin-right: 20px;
                border: 1px solid $border-color;
            }
        }

        .lists-section {
            padding: 10px 40px;
            flex: 1;
            min-height: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 30px;
            overflow: auto;

            .main-list {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                height: 100%;
                min-height: 0;

                .header {
                    padding: 5px;
                    background-color: $primary-dark;
                    color: $font-light;
                    font-size: 12px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    span {
                        margin-left: 5px;
                    }
                }

                .body {
                    flex: 1;
                    overflow-y: auto;

                    .list-item {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: $font-primary;
                        padding: 3px;
                        box-sizing: border-box;
                        border: 1px solid $border-color;
                        border-bottom: none;

                        &:nth-child(even) {
                            background-color: $background;
                        }

                        .list-add {
                            color: $primary-dark;
                        }

                        .list-label {
                            flex: 1;
                            font-size: 12px;
                            margin-left: 5px;
                        }

                        .list-actions {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            .close-icon {
                                margin-right: 3px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 20px;
                                width: 20px;
                                background-color: rgb(196, 128, 129);

                                svg {
                                    path {
                                        stroke: $font-white;
                                    }
                                }
                            }

                            .arrow-icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 20px;
                                width: 20px;
                                color: $font-white;
                                background-color: rgb(154, 196, 128);
                            }
                        }
                    }
                }

                .footer {
                    padding: 5px;
                    background-color: $primary-dark;
                    color: $font-light;
                    font-size: 12px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: rgb(154, 196, 128);

                    span {
                        margin-left: 5px;
                    }
                }
            }

            .secondary-list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                height: 100%;
                min-height: 0;
                grid-gap: 30px;
                width: 100%;
                min-width: 0;

                .empty-keywords {
                    font-size: 12px;
                    color: $font-primary;
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-width: 0;

                    .header {
                        padding: 5px;
                        background-color: $primary-dark;
                        color: $font-light;
                        font-size: 12px;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        span {
                            margin-left: 5px;
                        }
                    }

                    .list-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: $font-primary;
                        padding: 3px 5px;
                        box-sizing: border-box;
                        border: 1px solid $border-color;
                        font-size: 12px;

                        .name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .loading {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            width: 15px;
                        }

                        .delete {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0.3;
                            height: 100%;
                            width: 15px;
                            transition: 0.15s opacity;

                            &:hover {
                                cursor: pointer;
                                opacity: 1;
                                transition: 0.15s opacity;
                            }
                        }

                        &:nth-child(even) {
                            background-color: $background;
                        }

                        &:not(:last-of-type) {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .bottom-options {
            padding: 10px 40px 20px 40px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 12px;
            color: $font-primary;
        }

        .pagination-tab {
            padding: 10px 40px 20px 40px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: relative;

            .pagination {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .pagination-action {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    color: $primary-dark;
                }

                .pagination-pages {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .page {
                        color: $primary-dark;
                        font-size: 12px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 18px;
                        height: 20px;
                    }
                }
            }
        }
    }
}
