@import "designSystem/colors.scss";

.konfigurationen-wrapper {
    .konfigurationen-content {
        .table-wrapper {
            .details-button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $font-light;
                background-color: $primary-dark;
                padding: 2px 7px;

                &:hover {
                    cursor: pointer;
                    opacity: 0.9;
                }

                span {
                    margin-left: 5px;
                    white-space: nowrap;
                }
            }
        }
    }
}
