@import "designSystem/colors.scss";

.generation-wrapper {
    height: 100%;
    background-color: white;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .generation-content {
        flex: 1;

        .generation-table-wrapper {
            overflow: auto;
            height: 100%;

            table {
                thead {
                    background-image: url("../../../../../../assets/images/sub_header.png");
                    background-size: 100% calc(100% + 2px);

                    th {
                        background: unset !important;
                        white-space: nowrap;
                    }

                    .row-header {
                        border-right: 1px solid $border-color-dark;

                        th {
                            border-left: unset;
                            border-right: unset;
                            padding: 10px 10px 0 10px;
                            border-bottom: 0;

                            &.tip {
                                vertical-align: bottom;

                                div {
                                    font-size: 11px;
                                    display: flex;
                                    align-items: flex-end;
                                }
                            }

                            .section-header-item {
                                border-bottom: 1px solid $primary-dark;
                                padding-bottom: 3px;
                                margin-bottom: 3px;
                                width: fit-content;
                                font-size: 14px;
                                white-space: nowrap;
                                display: flex;
                                align-items: center;

                                .chart-type {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-left: 8px;
                                }

                                &.active,
                                &:hover {
                                    cursor: pointer;
                                    span {
                                        border-bottom: 1px solid;
                                    }
                                }
                            }
                        }
                    }

                    .options-row-cell {
                        border-bottom: 0;
                        border-top: 0;

                        .options-row {
                            padding: 0 5px 0 5px;
                            display: grid;
                            grid-template-columns: 150px minmax(auto, 285px) 250px 120px auto;
                            grid-column-gap: 40px;
                            grid-row-gap: 15px;
                            padding-bottom: 10px;

                            .custom-input {
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;

                                &.center {
                                    justify-content: center;
                                    align-items: center;
                                }

                                .custom-input-label {
                                    margin-bottom: 5px;
                                }

                                .custom-input-separator {
                                    margin: 0 5px;
                                }

                                .custom-input-fields {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;

                                    & > input,
                                    & > select {
                                        height: 20px;
                                        width: 100%;
                                        flex: 1;
                                        min-width: 0;
                                    }

                                    .custom-select {
                                        --rmsc-h: 18px !important;

                                        .select-panel {
                                            input[type="text"] {
                                                height: 25px !important;
                                            }
                                        }
                                    }
                                }

                                .send-button {
                                    min-width: 50px;
                                    height: 100%;
                                    margin-left: auto;
                                    max-width: 100px;

                                    button {
                                        white-space: normal;
                                        word-break: normal;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        &.row-filters {
                            td {
                                background-color: $border-color;
                                padding: 3px 5px;

                                select,
                                input {
                                    width: 100%;
                                }

                                .filter-select {
                                    width: -webkit-fill-available;
                                    text-align-last: center;
                                }
                            }
                        }

                        td {
                            .status-wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .status-disabled {
                                    width: 15px;
                                    height: 15px;
                                    border-radius: 5px;
                                    background-color: #eaeaea;
                                    border: 1px solid #aaaaaa;
                                }
                            }

                            .details-button {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                color: $font-light;
                                background-color: $primary-dark;
                                padding: 2px 7px;
                                &:hover {
                                    cursor: pointer;
                                    opacity: 0.9;
                                }
                                span {
                                    margin-left: 5px;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }

        .action-buttons {
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 180px 180px;
            grid-gap: 10px;
            width: fit-content;
            margin-left: auto;

            div {
                word-break: break-word;
                text-align: right;
            }
        }
    }

    .header-slider {
        position: relative;
        width: 100%;
        height: 40px;

        .header-slider-thumb {
            font-size: 0.9em;
            text-align: center;
            color: white;
            cursor: pointer;
            box-sizing: border-box;
            top: 1px;
            width: 15px;
            height: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:focus {
                outline: 0;

                .thumb-indicator {
                    border-width: 2px;
                }
            }

            &.key-0 {
                align-items: flex-start;
            }

            &.key-100 {
                align-items: flex-end;
            }

            .thumb-indicator {
                flex: 1;
                width: 7px;
                height: 100%;
                border: 1px solid #dddddd;
                background-color: #454d5d;
                margin-bottom: 17px;
            }
        }

        .header-slider-track {
            position: relative;
            background: #ddd;
            top: 15px;
            height: 1px;
        }

        .header-slider-mark {
            cursor: pointer;
            bottom: calc(50% - 22px);
            position: absolute;
            font-size: 11px;

            &.0 {
                margin-left: 5px;
            }
        }
    }
}
