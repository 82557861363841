@import "designSystem/colors.scss";

.euc-check-wrapper {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    h2 {
        margin: 0;
        font-size: 13px;
    }

    p {
        margin: 10px 0 0 0;
        font-size: 13px;
    }

    .error {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 23px;

        button {
            margin-top: 20px;
            width: 90px;
            height: 40px;
        }
    }

    .euc-check-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .tabs-wrapper {
            margin-top: 20px;
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;

            .tabs-selection {
                width: 100%;
                padding-top: 5px;
                display: flex;
                align-items: center;
                overflow: hidden;
                background-color: transparent;
                padding-left: 3px;
                position: absolute;
                top: 0;

                .tab-item {
                    margin-right: 2px;
                    font-size: 12px;
                    height: 31px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(180deg, #f3f3f3 0%, rgb(255, 255, 255) 80%);
                    border-top-right-radius: 3px;
                    border-top-left-radius: 3px;
                    box-shadow: 0px 0px 2px 0px rgba(169, 169, 169, 0.75);
                    text-decoration: none;
                    color: black;
                    border-bottom: 1px solid #e4e4e4;
                    padding: 0 10px;

                    .text {
                        padding-bottom: 2px;
                        border-bottom: 2px solid white;
                    }

                    &:hover,
                    &.active {
                        cursor: pointer;
                        background: white;
                        border-bottom: 1px solid white;

                        .text {
                            // border-bottom: 2px solid rgb(255, 220, 64);
                            border-bottom: 2px solid $accent-dark;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }

            .tabs-content {
                flex: 1 0 auto;
                margin-top: 35px;
                border: 1px solid #e4e4e4;
                padding: 30px;
                margin-left: 2px;
            }
        }
    }

    .euc-check-onboarding-wrapper {
        width: 100%;
        height: 100%;

        .euc-check-onboarding {
            display: flex;
            justify-content: space-between;
            background-color: rgb(239, 240, 241);
            padding: 20px;
            margin-top: 20px;

            .euc-check-list {
                //display: flex;
                flex-direction: column;
                //border: 2px solid rgb(254, 215, 85);
                border: 2px solid $accent-dark;
                font-size: 13px;
                max-width: 350px;
                background-color: rgb(255, 239, 181);

                p {
                    font-size: 12px;
                    margin: 0;
                    font-weight: 600;
                }

                .list-title {
                    // background-color: rgb(254, 215, 85);
                    background-color: $accent-dark;
                    padding: 5px 10px;
                    font-weight: 600;
                    word-break: break-word;
                }

                .list-body {
                    padding: 10px;
                    flex: 1 0 auto;

                    ul {
                        padding-inline-start: 20px;

                        li {
                            line-height: 17px;
                        }
                    }
                }
            }

            .euc-check-accesses {
                margin-left: 20px;

                p {
                    font-size: 13px;
                    font-weight: 600;
                    margin: 0;
                    word-break: break-word;
                }
            }
        }
    }

    .info-component {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 10px;

        .info-icon {
            font-size: 19px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 7px;
            flex-shrink: 0;
        }

        .info-text {
            font-size: 10px;
        }
    }
}

@media only screen and (max-width: 1070px) {
    .euc-check-wrapper {
        .euc-check-content {
            .tabs-wrapper {
                .tabs-content {
                    padding: 20px;
                }
            }
        }

        .euc-check-onboarding-wrapper {
            .euc-check-onboarding {
                flex-direction: column;

                .euc-check-accesses {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            .info-component {
                .info-text {
                    font-size: 12px;
                }
            }
        }
    }
}
