@import "designSystem/colors.scss";

.send-button-component {
    width: 25px;
    height: 100%;
    background-color: $primary-dark;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;
    }
}
