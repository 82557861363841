@import "designSystem/colors.scss";
.dashboard-page {
    width: 90% !important;
    max-width: 90% !important;
    .main-layout-section {
        max-width: 100% !important;
        width: auto !important;
        .section-wrapper {
            & > .content {
                padding: 30px 30px !important;
            }
        }
    }
    .dashboard-wrapper {
        height: 100%;
        &.full-screen {
            position: absolute;
            z-index: 90;
            left: 0;
            top: 0;
            width: 100%;
            background: #fff;
            padding: 30px;
        }
        .dashboard-content {
            display: grid;
            grid-template-areas: "chart-topbar chart-topbar" "chart-sidebar chart";
            grid-template-columns: 140px 1fr;
            grid-template-rows: 100px 1fr;
            grid-gap: 20px;
            height: 100%;
            min-height: 0;
            .chart-topbar {
                grid-area: chart-topbar;
                background-image: url(../../../../assets/images/sub_header.png);
                background-size: 100% calc(100% + 2px);
                color: $font-light;
                display: flex;
                font-size: 12px;
                .title {
                    padding: 10px;
                    margin-right: 20px;
                    font-weight: 600;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .content {
                    display: flex;
                    flex: 1;
                    min-width: 0;
                    .content-selects {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        box-sizing: border-box;
                        min-width: 0;
                        .input-row {
                            display: flex;
                            flex: 1;
                        }
                        .input-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex: 1;
                            min-width: 0;
                            &:not(:first-of-type) {
                                margin-left: 15px;
                            }
                            .input-title {
                                margin-right: 5px;
                                min-width: 50px;
                                text-align: right;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .input-field {
                                flex: 1;
                                width: 130px;
                                min-width: 0;
                                select,
                                .custom-select {
                                    width: 100%;
                                    height: 27px;
                                }
                            }
                        }
                        .button {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-left: 20px;
                        }
                    }
                    .apply-filter {
                        padding: 15px 10px 15px 0;
                        button {
                            height: 100%;
                            max-width: 50px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .chart-sidebar {
                grid-area: chart-sidebar;
                color: $primary-dark;
                background-color: $font-light;
                padding: 15px 5px;
                .item-wrapper {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    margin-bottom: 15px;
                    .item-title {
                        background-color: $primary-dark;
                        color: $font-light;
                        padding: 5px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .item-list {
                        .item {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            opacity: 0.9;
                            margin-top: 5px;
                            span {
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .icon {
                                opacity: 0;
                            }
                            &.active,
                            &:hover {
                                opacity: 1;
                                cursor: pointer;
                                font-weight: 600;
                                .icon {
                                    opacity: 1;
                                }
                            }
                        }
                        .link {
                            color: inherit;
                            text-decoration: inherit;
                        }
                    }
                }
            }
            .chart {
                grid-area: chart;
                height: 100%;
                width: 100%;
                min-width: 0;
            }
        }
    }
}
