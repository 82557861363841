@import "designSystem/colors.scss";

.file-select-component {
    width: 100%;
    height: 100%;

    &.disabled {
        .file-select-component__content {
            .filename {
                background-color: #dadada !important;
                border-color: #b3b3b3 !important;
                color: #a3a3a3;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }

    .file-select-component__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;

        .filename {
            min-height: 28px;
            line-height: 20px;
            flex: 1;
            word-break: unset;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            min-width: 0;
            height: 100%;
            font-size: 12px;
            padding: 3px 7px;
            border-radius: 3px;
            border: 1px solid $border-color;
            background-color: $background-light;
            color: $primary-dark;
        }
    }
}
