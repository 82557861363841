@import "designSystem/colors.scss";

.table-footer-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 12px;
    color: $font-primary;

    .pagination {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
            margin: 0 3px;
            height: 25px;
            width: 25px;
            border: 1px solid rgb(124, 124, 124);
            background-color: $background;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.disabled {
                opacity: 0.6 !important;
                cursor: not-allowed !important;
                pointer-events: none;
            }

            &.active {
                background-color: #bdbdbd;
                border: 1px solid #636363;
                color: #636363;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }

    .actions {
        select {
            margin-left: 5px;
            height: 27px;
            width: 50px;
        }
    }
}