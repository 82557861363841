@import "designSystem/colors.scss";

.file-tree-scan-modal {
    max-height: unset !important;
    width: 650px !important;
    height: 600px !important;

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px 50px;

        .tree-wrapper {
            border: 1px solid rgb(236, 236, 236);
            padding: 10px;
            box-sizing: border-box;
            flex: 1;

            .rc-tree {
                color: $font-primary;
                font-size: 14px;

                .rc-tree-treenode span.rc-tree-switcher:not(.rc-tree-switcher-noop) {
                    background-image: none;
                }

                .rc-tree-node-selected {
                    background-color: unset;
                    border: unset;
                }

                .rc-tree-treenode {
                    display: flex;
                    align-items: center;
                    height: 30px;

                    .rc-tree-node-content-wrapper {
                        display: flex;
                        align-items: center;
                    }

                    .rc-tree-icon__customize {
                        margin-right: 8px;
                    }
                }

                .rc-tree-treenode-selected {
                    border: 1px solid #c1d8f1;
                    background-color: #e0efff;
                }
            }
        }

        .action-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            button:last-of-type {
                margin-left: 20px;
            }
        }
    }
}
