@import "designSystem/colors.scss";

.main-layout {
    // background-image: url("../assets/images/main-bg.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px;
    box-sizing: border-box;

    .main-layout-sidebar {
        position: fixed;
        left: 0;
        top: 0;
        width: 65px;
        height: 100%;
        background-color: rgba(71, 76, 79, 0.81);
        display: flex;
        flex-direction: column;
        z-index: 85;
        transition: width 0.15s;

        .arrow-right {
            position: absolute;
            left: 65px;
            top: 215px;
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 13px solid rgba(71, 76, 79, 0.81);
            transition: left 0.15s;
        }

        &:hover {
            width: 270px;
            transition: width 0.15s;

            .arrow-right {
                left: 270px;
                transition: left 0.15s;
            }

            .sidebar-wrapper {
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(71, 76, 79, 0.81);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgb(133, 133, 133);
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(168, 168, 168);
                }

                .sidebar-title {
                    max-width: 180px;
                    margin: 60px auto;
                    max-height: 105px;
                    height: 100%;
                    width: 100%;

                    .logo-small {
                        display: none;
                    }

                    .logo-big {
                        display: block;
                        opacity: 0.3;
                    }
                }

                .sidebar-items {
                    .sidebar-item {
                        display: flex;
                        justify-content: flex-start;
                        padding: 0 35px;
                        margin-bottom: 30px;

                        &.disabled {
                            pointer-events: none;
                            opacity: 0.4;
                        }

                        &:hover {
                            cursor: pointer;
                        }

                        .sidebar-item-icon {
                            margin-left: 0;
                        }

                        .sidebar-item-content {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            opacity: 1;
                            visibility: visible;
                            transition: visibility 1s, opacity 0.1s;
                            transition-delay: 0.1s;
                        }
                    }
                }

                .sidebar-footer {
                    .sidebar-toggle-language {
                        .separator {
                            display: block;
                        }

                        .language {
                            display: block;
                        }
                    }

                    .sidebar-powered-by {
                        padding: 30px;

                        .powered-by {
                            display: block;

                            opacity: 0.8;
                            visibility: visible;
                            transition: visibility 1s, opacity 0.1s;
                            transition-delay: 0.1s;
                        }

                        .kpmg-logo {
                            position: relative;
                            width: auto;
                            left: auto;
                            text-align: auto;
                        }
                    }
                }
            }
        }

        .sidebar-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;

            .sidebar-title {
                margin-top: 20px;
                margin-bottom: 173px;
                color: white;
                padding: 0 10px;
                max-height: 32px;
                height: 100%;
                width: 100%;

                .logo-small {
                    display: block;
                    opacity: 0.8;
                }

                .logo-big {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .sidebar-items {
                display: flex;
                flex-direction: column;

                .sidebar-item {
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    margin-bottom: 30px;
                    height: 40px;
                    text-decoration: none;

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.4;
                    }

                    .sidebar-item-icon {
                        flex-shrink: 0;
                        margin-left: 25px;
                        max-width: 20px;
                        max-height: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .sidebar-item-content {
                        margin-left: 15px;
                        display: flex;
                        flex-direction: column;

                        visibility: hidden;
                        opacity: 0;

                        .sidebar-item-title {
                            color: white;
                            letter-spacing: 2px;
                            opacity: 0.8;
                            font-size: 16px;
                            line-height: 18px;
                            margin-bottom: 3px;
                            word-break: break-word;
                        }

                        .sidebar-item-description {
                            color: white;
                            letter-spacing: 1.5px;
                            font-size: 11px;
                            opacity: 0.3;
                            word-break: break-word;
                        }
                    }
                }
            }

            .sidebar-footer {
                margin-top: auto;
                padding-top: 30px;

                .sidebar-toggle-language {
                    display: flex;
                    justify-content: center;
                    color: white;

                    .separator {
                        margin: 0 10px;
                        display: none;
                        opacity: 0.8;
                    }

                    .language {
                        display: none;
                        opacity: 0.8;

                        &:hover {
                            cursor: pointer;
                        }

                        &.active {
                            display: block;
                            opacity: 1;
                        }
                    }
                }

                .sidebar-powered-by {
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    color: white;
                    padding: 30px 0;
                    box-sizing: border-box;
                    letter-spacing: 1px;
                    position: relative;

                    .powered-by {
                        white-space: nowrap;
                        visibility: hidden;
                        opacity: 0;
                        margin-right: 5px;
                    }

                    .kpmg-logo {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        text-align: center;

                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }
    }

    .main-layout-wrapper {
        width: 70%;
        max-width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .main-layout-section {
            width: 100%;
            max-width: 1280px;
            min-width: 960px;
            flex: 1;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.24);
            overflow-x: auto;

            .section-wrapper {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                & > .language-bar {
                    flex-basis: 30px;
                    flex-shrink: 0;
                    background-color: #efefef;
                    border-bottom: 1px solid #d2d2d2;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 12px;
                    padding: 0 100px;
                    box-sizing: border-box;

                    .language {
                        opacity: 0.6;

                        &.active {
                            opacity: 1;
                            font-weight: 500;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .separator {
                        margin: 0 5px;
                    }
                }

                & > .header {
                    flex: 0 0 auto;
                    min-height: 110px;
                    background-color: white;
                    width: 100%;
                    position: relative;
                    overflow: hidden;

                    .header-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px 100px;

                        h1 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 400;
                            word-break: break-word;
                        }

                        img {
                            margin-left: auto;
                            max-width: 180px;
                            max-height: 35px;
                        }
                    }

                    .navbar {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding-left: 100px;
                        overflow-x: auto;
                        padding-top: 5px;
                        box-sizing: border-box;

                        &::-webkit-scrollbar {
                            height: 8px;
                        }

                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: rgb(189, 189, 189);
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: rgb(144, 144, 144);
                        }

                        .navbar-item {
                            margin-right: 2px;
                            font-size: 12px;
                            min-width: 120px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: linear-gradient(180deg, rgb(235, 235, 235) 0%, rgb(255, 255, 255) 80%);
                            box-shadow: 0px 0px 3px 0px rgba(169, 169, 169, 0.75);
                            text-decoration: none;
                            color: black;
                            white-space: nowrap;
                            padding: 0 10px;

                            .text {
                                padding-bottom: 2px;
                                border-bottom: 2px solid white;
                            }

                            &:hover,
                            &.active {
                                cursor: pointer;
                                background: white;

                                .text {
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .yellow-bar {
                        bottom: 0;
                        position: absolute;
                        height: 18px;
                        width: 100%;
                    }
                }

                & > .content {
                    // display: flex;
                    flex-direction: column;
                    padding: 30px 100px;
                    flex: 1 0 auto;
                    width: 100%;
                    // height: 100%;
                    box-sizing: border-box;

                    & > .error {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;
                        font-size: 23px;

                        button {
                            margin-top: 20px;
                            width: 90px;
                            height: 40px;
                        }
                    }

                    .content-card {
                        box-sizing: border-box;
                        padding: 30px;
                        background-color: white;
                        flex: 1 0 auto;
                        border-top: 14px solid;
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.24);
                        width: 100%;
                        //height: 100%;
                    }
                }
            }
        }
    }

    .grid {
        display: grid;

        &.grid-2 {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 1070px) {
    .main-layout {
        padding: 30px 30px 30px 100px;

        .main-layout-wrapper {
            width: 100%;
            max-width: 100%;
            overflow: auto;
            overflow-y: hidden;

            .main-layout-section {
                width: fit-content;
                justify-content: flex-start;
                align-items: flex-start;

                .section-wrapper {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
