@import "designSystem/colors.scss";

.change-multiple-file-status-modal {
    max-height: unset !important;
    height: unset !important;
    width: 750px !important;

    .modal-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px 50px;

        .input-wrapper {
            display: flex;
            flex-direction: column;

            label {
                font-size: 12px;
                font-weight: 600;
                color: $font-primary;
                margin-bottom: 5px;
            }

            select {
                height: 30px;
                border: 1px solid $border-color;
                box-sizing: border-box;
            }

            .files-list {
                overflow: auto;
                max-height: 155px;
                overflow: auto;
                border: 1px solid $border-color;

                .file-row {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 15px;
                    color: $primary-dark;
                    font-size: 13px;

                    &.white {
                        background-color: white !important;
                    }

                    &:nth-child(even) {
                        background-color: $background;
                    }

                    .filename {
                        margin-right: 20px;
                        flex: 1;
                        min-width: 0;
                        word-break: break-word;
                    }

                    .coordinator {
                        display: flex;
                        align-items: center;

                        .coordinator-edit {
                            display: flex;
                            align-items: center;
                            margin-left: 10px;

                            &:hover {
                                cursor: pointer;
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }

        .action-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            button:last-of-type {
                margin-left: 20px;
            }
        }
    }
}
