@import "designSystem/colors.scss";

.graphen-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: $background;

    .axis-configuration {
        height: 40px;
        padding: 0 10px;
        width: 100%;
        background-image: url("../../../../../../assets/images/sub_header.png");
        background-size: 100% calc(100% + 2px);
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: $font-light;

        .inputs {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;

            .input-title {
                margin-right: 5px;
            }

            .input-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 15px;

                select,
                input {
                    min-width: 70px;
                    width: 100%;
                    height: 27px;
                }
            }
        }
    }

    .chart-wrapper {
        display: flex;
        flex-direction: column;
        border: 1px solid $border-color;
        flex: 1;
        position: relative;
        min-height: 400px;

        .chart-configuration {
            height: 30px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-image: url("../../../../../../assets/images/sub_header.png");
            background-size: 100% calc(100% + 2px);
            font-size: 12px;
            color: $font-light;
            position: relative;

            .chart-switch {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .chart-type {
                    margin: 0 4px;

                    &.active,
                    &:hover {
                        cursor: pointer;
                        color: $accent-dark;
                    }
                }

                .chart-data-count {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;

                    .count {
                        margin-left: 5px;
                    }
                }
            }

            .chart-options {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 1;

                .chart-option {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &.button {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        &.disabled {
                            pointer-events: none;
                            opacity: 0.7;
                        }

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        span {
                            margin-left: 7px;
                            letter-spacing: 0.5px;
                        }
                    }

                    .title {
                        color: $font-light;
                        margin-right: 5px;
                        white-space: nowrap;
                    }

                    .value {
                        color: $accent-dark;
                        white-space: nowrap;
                    }

                    &.hoverable:hover {
                        cursor: pointer;
                    }

                    .custom-selection {
                        width: 200px;
                        height: auto;
                        background-color: rgba(82, 86, 98, 0.9);
                        position: absolute;
                        z-index: 100;
                        top: 30px;
                        cursor: context-menu;

                        & > div {
                            padding: 10px;
                        }

                        select {
                            margin-top: 5px;
                            width: 100%;
                        }
                    }

                    .buttons-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-top: 10px;
                    }
                }
            }

            .filter-options {
                position: absolute;
                z-index: 100;
                top: 30px;
                right: 0;
                width: 250px;
                background-color: rgba(82, 86, 98, 0.9);
                display: flex;
                flex-direction: column;
                padding: 10px;

                .filter-section {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;

                    &:not(:last-of-type) {
                        margin-bottom: 15px;
                    }

                    .filter-title {
                        font-weight: 600;
                        letter-spacing: 0.5px;
                    }

                    .filter-input {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        & > span,
                        & > label {
                            margin-left: 10px;
                        }

                        input[type="text"],
                        select {
                            width: 100%;
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }

                        .custom-select {
                            .dropdown-heading {
                                height: 19px !important;

                                .panel-content {
                                    max-height: 200px;

                                    .item-renderer {
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                    }

                    .apply-filters {
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .chart-content {
            flex: 1;
            background-color: rgb(199, 199, 199);
            position: absolute;
            height: calc(100% - 30px);
            width: 100%;
            top: 30px;

            .chart-loader {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 90;
                background: rgba(255, 255, 255, 0.8);
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 50px;
            }
        }
    }
}
