// Main Palette
$accent-dark: #00338D;
$accent-light: #005EB8;
$primary-dark: #525662;
$primary-medium: #696b77;
$background: #f0f0f2;
$background-light: #fff;

// Texts Palette
$font-primary: #525662;
$font-dark: #292c31;
// $font-title: #202020;
$font-disabled: #b6b6b6;
$font-light: #eaeaea;
$font-white: #fff;

// Other
$border-color: #d9d9dc;
$border-color-dark: #7b7d83;
$input-background: #b4b5ba;
$error: #f44336;

$point-green: rgb(96, 243, 110);
$point-dark-gray: rgb(75, 75, 75);
$point-red: rgb(253, 90, 91);
$point-gray: hsl(0, 0%, 91%);
