@import "designSystem/colors.scss";

.navbar-tabs-wrapper {
    display: flex;
    flex-direction: column;
    // height: 100%;
    width: 100%;

    .navbar {
        z-index: 1;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        padding-top: 5px;
        padding-left: 5px;
        flex-shrink: 0;

        .navbar-item {
            margin-right: 2px;
            font-size: 12px;
            min-width: 120px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(180deg, rgb(235, 235, 235) 0%, rgb(255, 255, 255) 80%);
            box-shadow: 0px 0px 3px 0px rgba(169, 169, 169, 0.75);
            text-decoration: none;
            color: black;
            white-space: nowrap;
            padding: 0 15px;
            border-bottom: 1px solid rgb(233, 233, 233);

            .text {
                padding-bottom: 2px;
                border-bottom: 2px solid white;
            }

            &:hover,
            &.active {
                cursor: pointer;
                background: white;
                border-bottom-color: white;

                .text {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .fill {
            height: 30px;
            flex: 1;
            border-bottom: 1px solid #e9e9e9;
            margin-right: 5px;
        }
    }

    .tab-wrapper {
        flex: 1;
        border: 1px solid rgb(233, 233, 233);
        border-top: 0;
        padding: 20px;
        margin-left: 4px;
        flex-basis: auto;
    }
}
