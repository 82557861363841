@import "designSystem/colors.scss";

html,
body,
#root,
.App,
.App > div {
	margin: 0;
	height: 100%;
	font-family: Verdana, Arial, sans-serif;
	width: 100%;
}

div {
	box-sizing: border-box;
}

.kpmg-modal-overlay {
	z-index: 100;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;

	&.transparent {
		background-color: rgba(0, 0, 0, 0);
	}
}

.kpmg-modal {
	height: 100%;
	max-height: 615px;
	width: 850px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 10px rgb(134, 134, 134);

	&:focus {
		outline: 0;
	}

	.modal-header {
		display: flex;
		flex-direction: column;
		min-height: 60px;
		flex-shrink: 0;
		background-color: #c9d0dc;
		background-image: url("./assets/images/sub_header.png");
		background-size: 100% 100%;
		position: relative;
		padding: 10px 50px;

		h3 {
			margin: 0 50px 5px 0;
			font-size: 13px;
			color: $font-light;
		}

		p {
			font-size: 12px;
			margin: 0;
			color: $accent-dark;
		}

		.close-icon {
			color: $font-light;
			position: absolute;
			top: 10px;
			right: 10px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.modal-body {
		flex: 1;
		overflow: auto;
		background-color: $background-light;
	}

	.modal-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		min-height: 60px;
		flex-shrink: 0;
		background-color: $background-light;
		padding: 0 20px;
		box-sizing: border-box;
	}
}

.kpmg-table {
	width: 100%;
	border-collapse: collapse;

	&.stripped {
		tbody {
			tr {
				background-color: $background-light;

				&:hover {
					background-color: #e5e5e5;
				}

				&:nth-child(odd) {
					background-color: $background;

					&:hover {
						background-color: #e5e5e5;
					}
				}
			}
		}
	}

	&.secondary {
		border-collapse: separate;
		border-spacing: 0 10px;

		thead {
			tr {
				th {
					border: 0;
					height: 100%;
				}
			}
		}

		tbody {
			tr {
				background-color: #f7f7f7;

				&:hover {
					background-color: #f1f1f1;
				}

				td {
					text-align: left;
					border: 0;
					padding: 7px 5px;
					height: 100%;
				}
			}
		}
	}

	&.nowrap {
		tr,
		th,
		td {
			word-break: keep-all;
		}
	}

	thead {
		tr {
			th {
				color: $font-light;
				border: 1px solid #7b7d8370;
				font-weight: normal;
				font-size: 12px;
				word-break: break-word;
				padding: 5px;
				text-align: left;
				background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
					repeat;

				.table-head {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					&.column {
						flex-direction: column;
					}

					&:hover {
						cursor: pointer;
					}

					.sort-icon {
						margin-left: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				&.number-row {
					text-align: center !important;
				}

				input[type="number"],
				.input-number {
					text-align: center;
					-moz-appearance: textfield;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
	}

	tbody {
		tr {
			background-color: $background;

			&.empty {
				background-color: $background-light !important;

				td {
					border: none;
					text-align: left;
				}
			}

			&.title-row {
				td {
					background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
						repeat !important;
					border-color: #6f727d !important;
				}
			}

			td {
				position: relative;
				height: 20px;
				box-sizing: border-box;
				color: $font-primary;
				border: 1px solid $border-color;
				font-weight: normal;
				font-size: 12px;
				word-break: break-word;
				padding: 5px;
				text-align: left;

				&.number-row {
					text-align: center !important;
				}

				input[type="number"],
				.input-number {
					text-align: center;
					-moz-appearance: textfield;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				&.primary-dark {
					text-align: left;
					border-bottom: 1px solid $primary-dark;
					color: $font-light;
					background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MMjUj/zwAFnNycDIwwARDn+9fvEAEYB6SQMS654D9IBgYAKEwRbhTvZdoAAAAASUVORK5CYII=")
						repeat;
				}

				&.primary-medium {
					text-align: left;
					background-color: $primary-medium;
					border-bottom: 1px solid $primary-medium;
					color: $font-light;
					background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKUlEQVQYV2MsLG/9zwAFX79+ZWCECYA43NzcEAEYB6SQMS2n6j9IBgYA6ZoSlHcKs1kAAAAASUVORK5CYII=")
						repeat;
				}

				&.with-h-padding {
					padding-left: 20px;
					padding-right: 20px;
				}

				&.with-v-padding {
					padding-top: 20px;
					padding-bottom: 20px;
					vertical-align: top;
				}

				&.input-cell {
					&.dark-bg {
						background-color: $input-background;
					}

					input,
					select {
						width: 100%;
						// max-width: 100px;
						color: $font-primary;
						border: 1px solid $border-color;
						box-sizing: border-box;

						&.error {
							border-color: red;
						}
					}
				}

				.icon-description {
					display: flex;
					align-items: center;

					span {
						margin-left: 5px;
					}
				}

				.radio-button {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 auto;
					border: 1px solid $border-color;
					border-radius: 100%;
					width: 16px;
					height: 16px;
					background-color: $background-light;
					padding: 2px;

					.fill {
						background-color: $primary-dark;
						border-radius: 100%;
						width: 100%;
						height: 100%;
					}
				}

				.remove-row {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 16px;
					height: 16px;
					margin: 0 auto;
					background-color: $primary-dark;
					color: $font-light;
					font-size: 14px;

					&:hover {
						cursor: pointer;
						opacity: 0.9;
					}
				}

				.action-buttons {
					display: flex;

					.table-btn {
						margin: 0 3px;
						width: 20px;
						height: 20px;
						border: 1px solid $primary-medium;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $background-light;
						color: $primary-dark;

						svg {
							path,
							polyline {
								stroke: $primary-dark;
							}
						}

						&:hover {
							cursor: pointer;
							background-color: $background;
						}

						&.arrow {
							font-size: 22px;
						}
					}
				}
			}
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.error-message {
	font-size: 11px;
	font-weight: 500;
	color: $error;
	margin-top: 5px;
}

.flex {
	display: flex;

	&.center {
		justify-content: center;
		align-items: center;
	}

	&.align-center {
		align-items: center;
	}
}

input,
select,
textarea {
	color: $font-primary;
	border: 1px solid $border-color;
	box-sizing: border-box;
	font-family: Arial;

	&:disabled {
		background-color: #dadada !important;
		border-color: #b3b3b3 !important;
		cursor: not-allowed !important;
		color: #a3a3a3 !important;
		opacity: 1;
	}

	&.error {
		border-color: $error !important;
	}
}

.overflow-x {
	overflow-x: auto;
}

.custom-select {
	--rmsc-main: #4285f4 !important;
	--rmsc-hover: #f1f3f5 !important;
	--rmsc-selected: #e2e6ea !important;
	--rmsc-border: #ccc !important;
	--rmsc-gray: #aaa !important;
	--rmsc-bg: #fff !important;
	--rmsc-p: 10px !important;
	--rmsc-radius: 0 !important;
	--rmsc-h: 27px !important;

	color: $font-primary !important;
	height: 100% !important;
	width: 100% !important;

	&.loading {
		.dropdown-container {
			background-color: #cacaca !important;
			pointer-events: none !important;
		}
	}

	.dropdown-heading {
		padding: 3px;

		.dropdown-heading-value {
			span {
				color: #525662;
				font-size: 13px;
				font-family: Arial;
			}
		}

		svg {
			transform: scale(0.7);
			color: #525662;
		}
	}

	.dropdown-content {
		min-width: 200px;
		z-index: 5;

		.panel-content {
			.item-renderer {
				display: flex;
				align-items: center;
			}
		}
	}

	&.error {
		border-color: $error !important;
	}
}

.question-tooltip {
	max-width: 350px;
}

@media only screen and (max-width: 1070px) {
	.kpmg-modal {
		width: calc(100% - 40px) !important;
		height: calc(100% - 40px) !important;
	}
}
