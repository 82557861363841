@import "designSystem/colors.scss";

.user-list-modal {
    color: $font-light;
    min-height: unset !important;
    height: auto !important;
    max-height: 600px !important;
    width: 750px !important;
    font-size: 14px;

    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: $primary-dark;
        padding: 15px;

        .close-tooltip {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .content-body {
        display: flex;
        flex-direction: column;
        color: $font-primary;
        font-size: 12px;
        overflow: auto;
        background-color: $background-light;

        p {
            background-color: #f9f9f9;
            margin: 0;
            padding: 10px 15px;
        }

        .download-csv {
            text-align: right;
            padding: 15px 15px 0 15px;
        }

        .table-wrapper {
            padding: 15px;
            background-color: $background-light;

            table {
                thead {
                    tr {
                        th {
                            white-space: nowrap;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            text-align: left;
                        }

                        &:hover,
                        &.selected {
                            cursor: pointer;
                            background-color: #e0feff;
                        }
                    }
                }
            }

            .action-button {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}
